<template>
    <el-container>
        <el-row style="width: 100%">
            <el-col :span="24">
                <div class="cardTitle">提交详情(请勾选未通过的选项)</div>
                <el-button type="primary" @click="doSubmit" class="cardTitleBtn" style="margin-left:10px;">提交未通过项</el-button>
            </el-col>
            <el-col :span="24">
                <el-table :data="historyList" ref="historyList" @selection-change="handleSelectionChange" style="width: 100%">
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="questionTitle" label="问题名称" width="280px" sortable></el-table-column>
                    <el-table-column prop="questionTypeName" label="问题类型" width="100px" sortable></el-table-column>
                    <el-table-column prop="imageUrlList" label="现场情况" width="250px" sortable>
                        <template slot-scope="scope">
                            <div v-for="image in scope.row.imageUrlList" :key="image">
                                <el-image style="width: 30px; height: 30px; float:left; margin-right: 2px" fit="cover" v-if="/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(image)" :src="image" :preview-src-list="question.imageUrlList" :z-index="zIndex"></el-image>
                                <video v-else :src="image" controls="controls" style="width: 30px; height: 30px; float:left; margin-right: 2px">您的浏览器不支持 video 标签</video>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="90px" label="分值" sortable>
                        <template slot-scope="scope">
                            <span>{{scope.row.questionScoreMin}}~{{scope.row.questionScoreMax}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="submitScore" width="130px"  label="检查人员打分" sortable></el-table-column>
                    <el-table-column width="120px"  label="是否无此项" sortable>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.submitInvalid" type="success">无此项</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="90px">
                        <template slot-scope="scope">
                        <el-button
                            @click="auditList(scope.row)"
                            type="primary"
                            size="mini"
                        >详情
                        </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="failedReason" label="原因">
                        <template slot-scope="scope">
                            <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入原因"
                            v-model="scope.row.failedReason">
                            </el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <el-dialog
          title="详情"
          :visible.sync="dialogVisible"
          width="80%">
          <el-row>
            <el-col :span="24">
              <div class="table">
                <table style="width:100%" border="none">
                  <tr>
                    <td><el-tag>{{question.questionTypeName}}</el-tag></td>
                    <td colspan="7">{{question.questionTitle}}</td>
                  </tr>
                    <tr>
                    <td>评分标准</td>
                    <td colspan="7" v-html="question.questionStandardDesc">{</td>
                  </tr>
                  <tr>
                    <td>最高分</td>
                    <td>{{question.questionScoreMax}}</td>
                    <td>最低分</td>
                    <td>{{question.questionScoreMin}}</td>
                    <td>检查人员打分</td>
                    <td>{{question.submitScore}}</td>
                    <td>最终得分</td>
                    <td>{{question.resultScore}}</td>
                  </tr>
                  <tr>
                    <td>检查人员</td>
                    <td>{{question.submitStaffName}}</td>
                    <td>检查人员提交时间</td>
                    <td>{{question.submitHistoryTime}}</td>
                    <td>检查人员备注</td>
                    <td colspan="3">{{question.submitRemark}}</td>
                  </tr>
                  <tr>
                    <td>现场情况</td>
                    <td colspan="7">
                       <div v-for="image in question.imageUrlList" :key="image" class="imageOrVideo">
                          <el-image style="width: 200px; height: 200px" fit="cover" v-if="image.indexOf('.jpg')>-1 || image.indexOf('.png')>-1 || image.indexOf('.jpeg')>-1" :src="image" :preview-src-list="question.imageUrlList" :z-index="zIndex"></el-image>
                          <video v-else :src="image" controls="controls" style="width: 200px; height: 200px">您的浏览器不支持 video 标签</video>
                        </div>
                    </td>
                  </tr>
                </table>
              </div>
            </el-col>
          </el-row>
          <!-- <template slot-scope="scope"> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关闭</el-button>
            </span>
          <!-- </template> -->
        </el-dialog>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            userInfo: [],
            historyList: [],
            dialogVisible: false,
            question: {},
            radio: '',
            zIndex: 9999,
            auditStatus: [],
            // checked: false
        }
    },
    mounted() {
        let self = this
        self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        self.getHistoryList(self.$route.query.guid || null)
        self.historyGuid = self.$route.query.guid
    },
    methods: {
        getHistoryList(guid) {
            let self = this
            self.$axios.get(
                '/patrol-survey-submit-history/get/'+ guid,
                {
                    headers: { token: self.userInfo.token },
                }
            ).then(
                function (response) {
                self.historyList = response.data.data
                console.log(self.historyList);
                }
            ).catch(function (error) {
                self.$message({
                message: "提交详情获取失败：" + error.response.data.message,
                type: "warning",
                });
            });
        },
        auditList(row) {
            let self = this
            self.question = self.$underscore.clone(row);
            self.dialogVisible = true
        },
        handleSelectionChange() {
            let self = this
            self.checked = !self.checked
        },
        doSubmit() {
            let self = this
            let list = self.$refs.historyList.selection
            let detailList = []
            
            list.forEach(item => {
                let status = {}
                status.detailGuid = item.submitDetailGuid
                status.failedReason = item.failedReason
                detailList.push(status)
            })
            console.log(detailList);
            console.log(self.historyGuid);
            self.$axios.put(
                '/patrol-survey-submit-history/check',
                {
                    detailList: detailList,
                    historyGuid: self.historyGuid
                },
                {
                    headers: { token: self.userInfo.token },
                }
            ).then(function () {
                self.$message({
                    message: "提交成功",
                    type: "success",
                });
                self.$router.go(-1);
            }).catch(function () {
                self.$message({
                    message: "提交审核失败",
                    type: "warning",
                });
            });
        }
    }
}
</script>

<style>
    .table{
      margin-bottom: 10px;
    }
    .table table{
      border-right: 1px solid #EBEEF5;
      border-bottom: 1px solid #EBEEF5;
      border-collapse: collapse;
    }
    .table th,.table td{
      border-left: 1px solid #EBEEF5;
      border-top: 1px solid #EBEEF5;
      padding: 10px;
    }
    .imageOrVideo{
        display: inline-block;
        width: 25%;
        height: 180px;
        padding: 10px
    }
    .imageOrVideo .el-image,.imageOrVideo video{
        width: 100%;
        height: 100%;
        display: inline-block;
    }
</style>